<template>
    <div>
        <div class="top">
            <div class="title">管理收货地址</div>
        </div>
        <div class="list">
            <table border="0" cellspacing="0" cellpadding="0">
                <tr class="list-title">
                    <th width="150">收货人</th>
                    <th width="150">所属地区</th>
                    <th width="150">详细地址</th>
                    <th width="150">邮编</th>
                    <th width="150">联系电话</th>
                    <th width="150">操作</th>
                    <th width="150">设置</th>
                </tr>
            </table>
        </div>
        <div class="list-city" v-for="(item, index) in myCity" :key="index">
            <table border="0" cellspacing="0" cellpadding="0">
                <tr>
                    <td width="150">{{ item.RealName }}</td>
                    <td width="150">
                        {{ item.Province }}{{ item.City }}{{ item.District }}
                    </td>
                    <td width="150">{{ item.Address }}</td>
                    <td width="150">{{ item.Postcode }}</td>
                    <td width="150">{{ item.Phone }}</td>
                    <td width="150">
                        <span class="handle" @click="editAddr(item)">修改</span>
                        <span class="m">|</span>
                        <span class="handle" @click="delAddr(item)">删除</span>
                    </td>
                    <td width="150">
                        <span v-if="item.IsDefault || index == 0" class="active"
                            >默认地址</span
                        >
                        <span
                            v-else
                            style="cursor: pointer;"
                            @click="editAddr1(item)"
                            >设为默认</span
                        >
                    </td>
                </tr>
            </table>
        </div>
        <div class="addr">
            新增收货地址
        </div>
        <div class="form">
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
            >
                <el-form-item label="所在地区" prop="city">
                    <el-cascader
                        size="small "
                        :props="optionProps"
                        v-model="ruleForm.city"
                        :options="cityList"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="具体地址" prop="Address">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入具体地址"
                        v-model="ruleForm.Address"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="收件人" prop="RealName">
                    <el-input
                        style="width:150px"
                        placeholder="请输入收件人"
                        v-model="ruleForm.RealName"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="Phone">
                    <el-input
                        style="width:150px"
                        placeholder="请输入联系电话"
                        v-model="ruleForm.Phone"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="邮编" prop="Postcode">
                    <el-input
                        style="width:150px"
                        placeholder="请输入邮编"
                        v-model="ruleForm.Postcode"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-checkbox-group v-model="ruleForm.IsDefault">
                        <el-checkbox
                            label="设置为默认收货地址"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
        </div>
        <div class="button">
            <el-button @click="submitForm('ruleForm')">保存</el-button>
        </div>
        <el-dialog
            title="收货地址"
            :visible.sync="addrdialogVisible"
            width="30%"
        >
            <el-form
                :model="ruleForm1"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
            >
                <el-form-item label="所在地区" prop="city">
                    <el-cascader
                        size="small "
                        :props="optionProps"
                        v-model="ruleForm1.city"
                        :options="cityList"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="具体地址" prop="Address">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入具体地址"
                        v-model="ruleForm1.Address"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="收件人" prop="RealName">
                    <el-input
                        style="width:150px"
                        placeholder="请输入收件人"
                        v-model="ruleForm1.RealName"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="Phone">
                    <el-input
                        style="width:150px"
                        placeholder="请输入联系电话"
                        v-model="ruleForm1.Phone"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="邮编" prop="Postcode">
                    <el-input
                        style="width:150px"
                        placeholder="请输入邮编"
                        v-model="ruleForm1.Postcode"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-checkbox-group v-model="ruleForm1.IsDefault">
                        <el-checkbox
                            label="设置为默认收货地址"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addrdialogVisible = false">取 消</el-button>
                <el-button @click="submitForm1('ruleForm')">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import cityLevelThree from './cityLevelThree'
import { changedAddress, getUserAddressList, removeAddress } from './service'

export default {
    data() {
        return {
            cityList: [],
            myCity: [],
            optionProps: {
                value: 'label',
                label: 'label',
                children: 'children'
            },
            addrdialogVisible: false,
            ruleForm: {
                city: [],
                IsDefault: ['设置为默认收货地址']
            },
            ruleForm1: {
                city: [],
                IsDefault: []
            },
            rules: {
                city: [
                    {
                        required: true,
                        message: '请选择详细地区',
                        trigger: 'change'
                    }
                ],
                RealName: [
                    {
                        required: true,
                        message: '请选择详细地区',
                        trigger: 'change'
                    }
                ],
                Phone: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: ['blur', 'change']
                    },
                    {
                        pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
                        message: '请输入正确的手机号'
                    }
                ],

                Address: [
                    {
                        required: true,
                        message: '请选择具体地址',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    mounted() {
        this.cityList = cityLevelThree.list
        this.init()
    },
    methods: {
        // 删除收货地址
        delAddr(data) {
            this.$confirm('你确定要执行吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                removeAddress({
                    ID: data.ID
                }).then(() => {
                    this.$message.success('删除成功')
                    this.init()
                })
            })
        },
        editAddr1(data) {
            let obj = {
                ID: data.ID,
                Address: data.Address,
                Phone: data.Phone,
                RealName: data.RealName,
                Postcode: data.Postcode,
                IsDefault: true,
                city: [data.Province, data.City, data.District],
                Province : data.Province,
                City : data.City,
                District : data.District,
            }
            changedAddress(obj).then(() => {
                this.$message.success('操作成功')
                this.init()
            })
        },
        submitForm1(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let data = {}
                    data.Address = this.ruleForm1.Address
                    data.Phone = this.ruleForm1.Phone
                    data.RealName = this.ruleForm1.RealName
                    data.Postcode = this.ruleForm1.Postcode
                    data.ID = this.ruleForm1.ID ? this.ruleForm1.ID : 0
                    data.Province = this.ruleForm1.city[0]
                    data.City = this.ruleForm1.city[1]
                    data.District = this.ruleForm1.city[2]
                    if (this.ruleForm1.IsDefault.length > 0) {
                        data.IsDefault = true
                    } else {
                        data.IsDefault = false
                    }
                    changedAddress(data).then(() => {
                        this.$message.success('操作成功')
                        this.init()
                        this.addrdialogVisible = false
                    })
                } else {
                    return false
                }
            })
        },
        init() {
            getUserAddressList().then(res => {
                this.myCity = res.data
            })
        },
        // 修改地址
        editAddr(data) {
            this.addrdialogVisible = true
            this.ruleForm1 = {
                ID: data.ID,
                Address: data.Address,
                Phone: data.Phone,
                RealName: data.RealName,
                IsDefault: data.IsDefault ? ['设置为默认收货地址'] : [],
                Postcode: data.Postcode,
                city: data.Province
                    ? [data.Province, data.City, data.District]
                    : []
            }
            // console.log(this.ruleForm1)
        },
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let data = {}
                    data.Address = this.ruleForm.Address
                    data.Phone = this.ruleForm.Phone
                    data.RealName = this.ruleForm.RealName
                    data.Postcode = this.ruleForm.Postcode
                    data.ID = this.ruleForm.ID ? this.ruleForm.ID : 0
                    data.Province = this.ruleForm.city[0]
                    data.City = this.ruleForm.city[1]
                    data.District = this.ruleForm.city[2]
                    if (this.ruleForm.IsDefault.length > 0) {
                        data.IsDefault = true
                    } else {
                        data.IsDefault = false
                    }
                    changedAddress(data).then(() => {
                        this.$message.success('添加成功')
                        this.ruleForm.ID = 0
                        this.init()
                        this.resetForm.IsDefault = false
                        this.$refs[formName].resetFields()
                    })
                } else {
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        }
    }
}
</script>

<style scoped lang="less">
.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        background: #dc2310;
        color: #ffffff;
        border: none;
        padding: 15px 40px;
    }
}
.list-city {
    margin: 15px 0;
    border: 1px solid #f4f4f4;
    padding: 20px 0;
    font-size: 13px;
    color: #333333;
    td {
        text-align: center;
    }
    .handle {
        cursor: pointer;
    }
    .m {
        margin: 0 5px;
    }
    .active {
        background: rgba(27, 152, 170, 0.2);
        padding: 2px 20px;
        color: #1b98aa;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }
}

.addr {
    font-family: 'ct';
    margin: 20px 0;
    color: #1b98aa;
}

.list {
    margin-top: 20px;
}

.list-title {
    background: #eeeeee;
    height: 40px;
    text-align: center;
}

.form {
    background: #fdfdfd;
    border: 1px solid #dcdcdc;
    padding: 30px 300px 0 20px;
}
</style>
<style>
>>> .el-input__inner {
    border-radius: 0px !important;
}
</style>
